4<template>
  <div class="mt-4">
    <h4 class="header">
      Email Settings
    </h4>

    <!------- USERNAME AND PASSWORD --------------------------------------------------------------->
    <div class="col-md-12 mt-4 pl-0">
      <div class="row">
        <div class="col-md">
          <span class="user-settings">
            Username:
          </span>
          <p class="setting-value">
            {{ emailAddress || '----' }}
          </p>
        </div>

        <div class="col-md">
          <span class="user-settings">
            Password:
          </span>
          <p class="setting-value font-italic">
            Use the email account's password
          </p>
        </div>
      </div>
    </div>

    <!------- SETTING OPTIONS --------------------------------------------------------------------->
    <ul id="setting-tabs" class="nav nav-tabs" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          id="secure-settings-tab"
          class="nav-link active"
          type="button"
          role="tab"
          aria-controls="profile"
          aria-selected="false"
          @click="changePortTo('secure-settings-tab')"
        >
          Secure SSL Settings (Recommended)
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          id="non-ssl-settings-tab"
          class="nav-link"
          type="button"
          role="tab"
          aria-controls="profile"
          aria-selected="false"
          @click="changePortTo('non-ssl-settings-tab')"
        >
          Non-SSL Settings
        </button>
      </li>
    </ul>

    <div class="email-settings-container row pt-4 mb-5">
      <!------- INCOMING SERVER ------------------------------------------------------------------->
      <div class="col-md-6 server-details pt-0">
        <div class="col-md-12 server-name-div">
          <span class="server-name">
            Incoming Mail Server
          </span>
          <hr>
        </div>

        <div class="col-md-12 pl-0">
          <span class="setting-name">
            Server HostName:
          </span>
          <p class="setting-value">
            {{ serverName || '----' }}
          </p>
        </div>

        <div class="row">
          <div class="col-md-6">
            <span class="setting-name">
              IMAP Port:
            </span>
            <p class="setting-value">
              {{ imapPort }}
            </p>
          </div>

          <div class="col-md-6">
            <span class="setting-name">
              POP3 Port:
            </span>
            <p class="setting-value">
              {{ pop3Port }}
            </p>
          </div>
        </div>
      </div>

      <!------- OUTGOING SERVER ------------------------------------------------------------------->
      <div class="col-md-6 server-details pt-0">
        <div class="col-md-12 server-name-div">
          <span class="server-name">
            Outgoing Mail Server
          </span>
          <hr>
        </div>

        <div class="col-md-12 pl-0">
          <span class="setting-name">
            Server HostName:
          </span>
          <p class="setting-value">
            {{ serverName || '----' }}
          </p>
        </div>

        <div class="col-md-12 pl-0">
          <span class="setting-name">
            SMTP Port:
          </span>
          <p class="setting-value">
            {{ smtpPort }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Settings',
  props: {
    domain: String,
  },
  data() {
    return {
      imapPort: '',
      pop3Port: '',
      smtpPort: '',
      ports: {
        secure: {
          imapPort: 993,
          pop3Port: 995,
          smtpPort: 465,
        },
        unsecure: {
          imapPort: 143,
          pop3Port: 110,
          smtpPort: 587,
        },
      },
    }
  },
  computed: {
    emailAddress() {
      return this.domain ?
        `admin@${this.domain}` :
        ''
    },

    serverName() {
      return this.domain ?
        `mail.${this.domain}` :
        ''
    },
  },
  async mounted() {
    this.setPorts()
  },
  methods: {
    changePortTo(settingsTabId) {
      // Switch Port settings
      const secureSettings = settingsTabId === 'secure-settings-tab'
      this.setPorts(secureSettings)

      // Update selected tab
      const tabs = document.querySelectorAll('.nav-link')
      tabs.forEach(function (tab) {
        if (tab.id === settingsTabId) {
          tab.classList.add('active')
        } else {
          tab.classList.remove('active')
        }
      })
    },
    setPorts(secureSettings = true) {
      const ports = this.ports
      this.imapPort = secureSettings ? ports.secure.imapPort : ports.unsecure.imapPort
      this.pop3Port = secureSettings ? ports.secure.pop3Port : ports.unsecure.pop3Port
      this.smtpPort = secureSettings ? ports.secure.smtpPort : ports.unsecure.smtpPort
    },
  },
}
</script>

<style lang="scss" scoped>
.email-settings-container {
  background-color: #f4f4f4;
  border-radius: 0 5px 5px 5px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  margin-left: 0;

  .server-details {
    padding: 2.5em 2.5em 0 2.5em;
  }

  .server-name-div {
    padding-left: 0 !important;
    margin-bottom: 1em;
  }

  .server-name {
    font-weight: bold;
    font-size: 1.25em;
  }

  .setting-value {
    font-weight: lighter;
    font-size: 1em;
    word-break: break-word;
  }

  .setting-name {
    font-weight: bold;
    font-size: 1em;
  }
}

.nav-link {
  font-weight: bold;
}

.nav-link.active {
  border: transparent;
  color: white;
  background-color: $ct-ui-color-10;
  border: solid 1px #b4b4b4;
  border-bottom: none;
}
.nav-link:not(.active) {
  opacity: .75;
  background-color: #f4f4f4;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.nav.nav-tabs {
  margin-bottom: -1px;
}
.user-settings {
  font-weight: bold;
  font-size: 1em;
}

@media only screen and (max-width: 767px) {
  .email-settings-container {
    margin-left: 0;
    margin-right: 0;
  }
}
</style>
